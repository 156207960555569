// Circular Publication api
export const circularPublicationBaseUrl = '/iabm/circular-publications/'
export const circularPublicationList = circularPublicationBaseUrl + 'list'
export const circularPublicationToggleStatus = circularPublicationBaseUrl + 'toggle-status'
export const circularPublicationStore = circularPublicationBaseUrl + 'store'
export const circularPublicationUpdate = circularPublicationBaseUrl + 'update'
export const circularPublicationDestroy = circularPublicationBaseUrl + 'destroy'
export const circularList = circularPublicationBaseUrl + 'circular-publication-list'
export const circularPublication = circularPublicationBaseUrl + 'circular-publication-data'
export const circularPublicationBatch = circularPublicationBaseUrl + 'batch'
export const circularPublicationPublish = circularPublicationBaseUrl + 'publish'

// Booking api
export const booingBaseUrl = '/iabm/booking/'
export const bookingApplication = booingBaseUrl + 'application'
export const bookingApplicationStore = booingBaseUrl + 'store'
export const bookingApplicationCheck = booingBaseUrl + 'room-book-check'
export const bookingApplicationList = booingBaseUrl + 'list'
export const bookingCancel = booingBaseUrl + 'cancel'
export const cancelComments = booingBaseUrl + 'cancel-comments'
export const roomList = booingBaseUrl + 'room-list'
export const roomBookedList = booingBaseUrl + 'room-book-list'
export const roomRentList = '/config/room-rents/list'

// Personal Info api
export const personalInfoBaseUrl = '/iabm/personal-infos/'
export const personalInfoList = personalInfoBaseUrl + 'list'
export const personalDataList = personalInfoBaseUrl + 'personal-info-list'
export const personalInfoDetails = personalInfoBaseUrl + 'personal-info-details'
export const personalInfoRejactList = personalInfoBaseUrl + 'reject-list'
export const trainerInfoList = personalInfoBaseUrl + 'trainer-list'
export const personalInfoStore = personalInfoBaseUrl + 'store'
export const personalInfoUpdate = personalInfoBaseUrl + 'update'
export const personalInfoShow = personalInfoBaseUrl + 'show'
export const personalInfoTrainerShow = personalInfoBaseUrl + 'trainer-show'
export const personalInfoApprove = personalInfoBaseUrl + 'approve'
export const personalInfoReject = personalInfoBaseUrl + 'reject'
export const personalInfoData = personalInfoBaseUrl + 'personal-data'
export const allTrainerData = personalInfoBaseUrl + 'all-trainer-list'
export const rejectedList = personalInfoBaseUrl + 'rejected-list'
export const designationWiseUser = personalInfoBaseUrl + 'officer-list'

// Training Application api
export const trainingApplicationBaseUrl = '/iabm/training-applications/'
export const trainingApplicationList = trainingApplicationBaseUrl + 'list'
export const trainingApplicationStore = trainingApplicationBaseUrl + 'store'
export const trainingApplicationUpdate = trainingApplicationBaseUrl + 'update'
export const trainingApplicationShow = trainingApplicationBaseUrl + 'show'
export const trainingApplicationApprove = trainingApplicationBaseUrl + 'approve'
export const trainingApplicationReject = trainingApplicationBaseUrl + 'reject'
export const trainingApplicationPersonalInfo = trainingApplicationBaseUrl + 'personal-info'
export const trainingApplicationCircularPublicationInfo = trainingApplicationBaseUrl + 'circular-publication-info'

export const bookingBookingCalender = booingBaseUrl + 'booking-calender'

// Request for document api
export const requestDocumentList = '/iabm/request-for-document/list'
export const requestDocumentStore = '/iabm/request-for-document/store'
export const requestDocumentUpdate = '/iabm/request-for-document/update'
export const requestDocumentToggleStatus = '/iabm/request-for-document/toggle-status'
export const personalInfo = '/iabm/personal-infos/personal/'

// Request for complain api
export const complainList = '/iabm/complain-recommendation/list'
export const complainStore = '/iabm/complain-recommendation/store'
export const complainUpdate = '/iabm/complain-recommendation/update'
export const complainToggleStatus = '/iabm/complain-recommendation/toggle-status'
export const personalInfoAdmin = '/iabm/personal-infos/personal-admin/'

// Circular Publication api
export const lessonAssignCourseWiseBaseUrl = '/iabm/lesson-assign-course-wise/'
export const lessonAssignCircularWiseList = lessonAssignCourseWiseBaseUrl + 'list'
export const lessonAssignCourseWiseStore = lessonAssignCourseWiseBaseUrl + 'store'
export const lessonAssignCourseWiseUpdate = lessonAssignCourseWiseBaseUrl + 'update'
export const lessonAssignCourseWiseShow = lessonAssignCourseWiseBaseUrl + 'show'

// Booking api
export const invitationBaseUrl = '/iabm/invitation/'
export const invitationCircular = invitationBaseUrl + 'circular-info'
export const invitationCircularList = invitationBaseUrl + 'circular-list'
export const invitationStore = invitationBaseUrl + 'store'
export const invitationUpdate = invitationBaseUrl + 'update'
export const invitationList = invitationBaseUrl + 'list'
export const invitationShow = invitationBaseUrl + 'show'
export const invitationAddCheck = invitationBaseUrl + 'add-check'
export const invitationPdfDownload = invitationBaseUrl + 'pdf-download'
export const invitationApproved = invitationBaseUrl + 'trainer-apply'
export const invitationMail = invitationBaseUrl + 'send-mail'
export const invitationPdf = invitationBaseUrl + 'listPdf'

// Booking api
export const duplicateCertificateRequestBaseUrl = '/iabm/duplicate-certificate-request/'
export const duplicateCertificateRequestList = duplicateCertificateRequestBaseUrl + 'list'
export const duplicateCertificateRequestShow = duplicateCertificateRequestBaseUrl + 'show'
export const duplicateCertificateRequestApprove = duplicateCertificateRequestBaseUrl + 'approved'
export const duplicateCertificateRequestReject = duplicateCertificateRequestBaseUrl + 'rejected'

// Sponsored Training api
export const SponsoredTrainingList = '/iabm/sponsored-training/list'
export const SponsoredTrainingApproveReject = '/iabm/sponsored-training/ar-store'
